// import React from 'react';
// import { navigate } from 'gatsby';
// import { toast } from 'react-toastify';
// import Button from '../../components/atoms/Button/Button';
// import Icon from '../../components/atoms/Icon/Icon';
import {setStorage, getStorage} from '../general';
// import { LOCAL_STORE } from '../../components/molecules/Localisr/Statics';

const WARRANTY_STORE = 'warranty_map';

function addWarrantyBundle(product, warranty) {
    const warranty_string = getStorage(WARRANTY_STORE);

    let warrantyMap = [];
    if(warranty_string !== null) {
        warrantyMap = JSON.parse(warranty_string);
    }

    // if(warranty_string === null) {
    //     const warrantyMap = [];
    //     const tempBundle = {
    //         product: product,
    //         warranty: warranty,
    //     }
    //     warrantyMap.push(tempBundle);
    //     setStorage(LOCAL_STORE.WARRANTY_MAP, JSON.stringify(warrantyMap));
    // } else {

    //     const warrantyMap = JSON.parse(warranty_string);
    //     const tempBundle = {
    //         product: product,
    //         warranty: warranty,
    //     }
    //     warrantyMap.push(tempBundle);
    //     setStorage(LOCAL_STORE.WARRANTY_MAP, JSON.stringify(warrantyMap));
    // }

    // const warrantyMap = JSON.parse(warranty_string);
    const tempBundle = {
        product: product,
        warranty: warranty,
    }
    warrantyMap.push(tempBundle);
    setStorage(WARRANTY_STORE, JSON.stringify(warrantyMap));
}

function getAttachedWarranty(productId) {
    let result = false;
    const warranty_string = getStorage('warranty_map');
    let warrantyMap = [];
    if(warranty_string !== null) {
        warrantyMap = JSON.parse(warranty_string);
    }

    if(warrantyMap.length > 0) {
        const filteredWarrantyMap = warrantyMap.filter((temp) => temp.product?.id === productId);
        // if(filteredWarrantyMap.length !== 0) result = filteredWarrantyMap[0].warranty;
        if(filteredWarrantyMap.length !== 0) result = filteredWarrantyMap.map((obj) => obj.warranty);
    }
    return result;
}


function isWarranty(product_id) {
    let result = false;
          
    switch (product_id) {
        case 34523:
            result = true;
            break;
        case 34522:
            result = true;
            break;
        case 34524:
            result = true;
            break;
        default:
            result = false;
            break;
    }
  
    return result;
}

function computeWarrantyPrice (warranty_product_id, price) {
    let percentage = 0;
          
    switch (warranty_product_id) {
        case 34523:
            percentage = 0.15; //0.12;
            break;
        case 34522:
            percentage = 0.15;
            break;
        case 34524:
            percentage = 0.15;
            break;
        default:
            percentage = 0.12;
            break;
    }
  
    return Math.ceil(price * percentage);
}

function hasAttachedWarranty (product_id) {
    let result = false;
    const warranty_string = getStorage('warranty_map');
    const warranty_map = JSON.parse(warranty_string);
    if(warranty_map === null) return result;
        
    // if(warranty_map.length > 0) {
    //     for(let x = 0; x < warranty_map.length; x++) {
    //         if(warranty_map[x].product.id === product_id) {
    //             result = warranty_map[x].warranty;
    //         }
    //     }
    // }

    if(warranty_map.length > 0) {
        const tempList = warranty_map.filter((bundle) => bundle.product.id === product_id).map((bundle) => bundle.warranty);
        if(tempList.length > 0) result = tempList;
    }

    return result;
}

function getProductFromWarranty (warranty_id) {
    let result = false;
    
    const warranty_string = getStorage('warranty_map');
    const warranty_map = JSON.parse(warranty_string);
    if(warranty_map === null) return result;

    if(warranty_map.length > 0) {
        const tempList = warranty_map.filter((bundle) => bundle.warranty.id === warranty_id).map((bundle) => bundle.product);
        if(tempList.length > 0) result = tempList;
    }

    return result;
}

// function needs improvement with the connection to the mapping
function getMaxQuantity (product, warranties) {
    let maxQty = 0;

    if(warranties === undefined || warranties?.length === 0) return maxQty;

    const warranty_string = getStorage('warranty_map');
    const warranty_map = JSON.parse(warranty_string);
    if(warranty_map === null) return maxQty;

    const filteredWarrantyBundle = warranty_map.filter((bundle) => bundle.product.id === product.id);
    if(filteredWarrantyBundle.length > 0) {
        const upperbound = product.quantity;
        const lowerbound = filteredWarrantyBundle.reduce((accumulator, value) => {
            return accumulator + value.warranty.quantity;
        }, 0);        
        maxQty = upperbound - lowerbound;
    }
    return maxQty;
}

function deleteAttachedWarranty (warranty) {
    let result = false;
    const warranty_string = getStorage('warranty_map');
    const warranty_map = JSON.parse(warranty_string);

    if(warranty_map === null) return result;

    const filteredMap = warranty_map.filter((tempWarranty) => {
        if(tempWarranty.warranty.id !== warranty.id) {
            return tempWarranty;
        }
        return false;
    })
    setStorage(WARRANTY_STORE, JSON.stringify(filteredMap));
    return;
}

function destroyMapping () {
    let emptyArray = [];
    setStorage(WARRANTY_STORE, JSON.stringify(emptyArray));
    return;
}

export { 
    addWarrantyBundle,
    getAttachedWarranty,
    isWarranty,
    computeWarrantyPrice,
    hasAttachedWarranty,
    deleteAttachedWarranty,
    getProductFromWarranty,
    getMaxQuantity,
    destroyMapping,
};