import React from 'react'

const Latitude = () => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.94 25.15">
        <path class="cls-1" fill="#fff" d="M48.28,22.8,2.48,24.6m44-23a.9.9,0,0,1,.9.9h0V22.8a.9.9,0,0,1-.9.9h-44a.9.9,0,0,1-.9-.9h0V2.5a.9.9,0,0,1,.9-.9h44"/>
        <path class="cls-2" fill="#0046aa" d="M20,17.42a24.59,24.59,0,0,0,12.3,3.3,24.59,24.59,0,0,0,12.3-3.3V5.61H20Z"/>
        <path class="cls-1" fill="#fff" d="M23.4,8h.48v3.37H23.4Z"/>
        <path class="cls-1" fill="#fff" d="M27.25,9.87v1.46h-.47V9.92c0-.5-.25-.75-.68-.75a.76.76,0,0,0-.81.85v1.31h-.46V8.78h.44v.39a1.09,1.09,0,0,1,.92-.41A1,1,0,0,1,27.25,9.87Z"/>
        <path class="cls-1" fill="#fff" d="M29.54,11.18a.87.87,0,0,1-.56.18.73.73,0,0,1-.81-.8V9.16h-.43V8.78h.43V8.22h.46v.56h.73v.38h-.73v1.38A.38.38,0,0,0,29,11a.54.54,0,0,0,.36-.12Z"/>
        <path class="cls-1" fill="#fff" d="M32.31,10.21H30.24a.85.85,0,0,0,.92.75,1,1,0,0,0,.74-.31l.25.3a1.4,1.4,0,0,1-2.38-.89,1.25,1.25,0,0,1,1.28-1.3,1.23,1.23,0,0,1,1.27,1.31S32.31,10.17,32.31,10.21Zm-2.07-.33h1.63a.79.79,0,0,0-.82-.73A.79.79,0,0,0,30.24,9.88Z"/>
        <path class="cls-1" fill="#fff" d="M34.33,8.76v.45h-.11a.76.76,0,0,0-.8.86v1.27H33V8.78h.44v.43A1,1,0,0,1,34.33,8.76Z"/>
        <path class="cls-1" fill="#fff" d="M37.17,10.21H35.1A.87.87,0,0,0,36,11a1,1,0,0,0,.74-.31L37,11a1.39,1.39,0,0,1-2.37-.89,1.24,1.24,0,0,1,1.28-1.3,1.23,1.23,0,0,1,1.26,1.31S37.17,10.17,37.17,10.21ZM35.1,9.88h1.63a.79.79,0,0,0-.81-.73A.79.79,0,0,0,35.1,9.88Z"/>
        <path class="cls-1" fill="#fff" d="M37.49,11.06l.2-.36a1.64,1.64,0,0,0,.89.26c.43,0,.61-.13.61-.34,0-.58-1.61-.08-1.61-1.1,0-.45.41-.76,1.06-.76a1.91,1.91,0,0,1,.93.23l-.2.37a1.31,1.31,0,0,0-.73-.21c-.41,0-.61.15-.61.35,0,.61,1.62.12,1.62,1.11,0,.46-.42.75-1.1.75A1.88,1.88,0,0,1,37.49,11.06Z"/>
        <path class="cls-1" fill="#fff" d="M41.66,11.18a.87.87,0,0,1-.56.18.72.72,0,0,1-.8-.8V9.16h-.44V8.78h.44V8.22h.46v.56h.73v.38h-.73v1.38c0,.28.14.43.4.43a.58.58,0,0,0,.36-.12Z"/>
        <path class="cls-1" fill="#fff" d="M27.9,13.64v1.18h1.69v.41H27.9V16.6h-.49V13.22H29.8v.42Z"/>
        <path class="cls-1" fill="#fff" d="M31.75,14v.45h-.11a.76.76,0,0,0-.81.86V16.6h-.46V14h.44v.43A1,1,0,0,1,31.75,14Z"/>
        <path class="cls-1" fill="#fff" d="M34.6,15.47H32.52a.87.87,0,0,0,.93.75,1,1,0,0,0,.74-.31l.26.3a1.39,1.39,0,0,1-2.39-.89,1.28,1.28,0,1,1,2.55,0S34.6,15.43,34.6,15.47Zm-2.08-.33h1.64a.79.79,0,0,0-.82-.73A.8.8,0,0,0,32.52,15.14Z"/>
        <path class="cls-1" fill="#fff" d="M37.56,15.47H35.48a.87.87,0,0,0,.93.75,1,1,0,0,0,.74-.31l.25.3a1.25,1.25,0,0,1-1,.42A1.28,1.28,0,0,1,35,15.32a1.28,1.28,0,1,1,2.55,0S37.56,15.43,37.56,15.47Zm-2.08-.33h1.64a.79.79,0,0,0-.82-.73A.8.8,0,0,0,35.48,15.14Z"/>
        <path class="cls-2" fill="#0046aa" d="M17.24,7.66a1.13,1.13,0,1,1-1.12-1.13A1.12,1.12,0,0,1,17.24,7.66Z"/>
        <path class="cls-2" fill="#0046aa" d="M16.12,10.07a1.13,1.13,0,1,0,1.12,1.12A1.12,1.12,0,0,0,16.12,10.07Z"/>
            <circle class="cls-2" fill="#0046aa" cx="9.04" cy="7.66" r="1.13"/>
        <path class="cls-2" fill="#0046aa" d="M16.12,13.61a1.13,1.13,0,1,0,1.12,1.12A1.12,1.12,0,0,0,16.12,13.61Z"/>
            <circle class="cls-2" fill="#0046aa" cx="12.58" cy="7.66" r="1.13"/>
        <polygon class="cls-2" fill="#0046aa" points="6.63 6.53 4.38 6.53 4.38 19.4 17.24 19.4 17.24 17.15 6.63 17.15 6.63 6.53"/>
        <path class="cls-3" fill="#e6e6e6" d="M46.48.7h-44A1.79,1.79,0,0,0,.68,2.5h0V22.8a1.79,1.79,0,0,0,1.8,1.8h44a1.79,1.79,0,0,0,1.8-1.8h0V2.5A1.79,1.79,0,0,0,46.48.7Zm0,.9a.9.9,0,0,1,.9.9h0V22.8a.9.9,0,0,1-.9.9h-44a.9.9,0,0,1-.9-.9h0V2.5a.9.9,0,0,1,.9-.9h44"/>
    </svg>
)

export default Latitude