import jwt from 'jsonwebtoken'
import { v4 } from 'uuid'

/**
 * BigCommerce API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 3 is assumed
 * @param   {String} overrideCartId (Optional) Pass the Cart ID that should be used, regardless of whats in session
 * 
 * @return  {Object}                {response, status}
 *
    import { bcApi } from '../helpers/bigcommerce'

    bcApi('endpoint', 'POST', bodyObject, version).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function bcApi(endpoint, method, body, version, overrideCartId) {
  const options = {
    method: method ? method : 'GET',
    credentials: 'same-origin',
    mode: 'same-origin'
  };

  if (body) {
    let bodyString = body;
    if (typeof body === 'object') {
      bodyString = JSON.stringify(body);
    }

    options.body = bodyString;
  }

  const parseJson = async response => {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      return json;
    } catch (err) {
      return text;
    }
  };

  if (!(typeof fetch === 'undefined') && !(typeof window === 'undefined')) {
    return await fetch(
      `/.netlify/functions/bigcommerce?${
        version ? `v=${version}&` : ''
      }${
        overrideCartId ? `overrideCartId=${overrideCartId}&` : ''
      }endpoint=${btoa(endpoint)}`,
      options
    ).then(async res => ({ response: await parseJson(res), status: res.status }));
  } else {
    return {response: null, status: 404}
  }
}

/**
 * BigCommerce WebDAV Helper
 * 
 * Connects to the WebDAV to carry out required functions
 * 
 * @param   {String} action         Action to be taken
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { webDav } from '../helpers/bigcommerce'

    webDav('action', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function webDav(action, body) {
  const options = {
    method: 'POST',
    credentials: 'same-origin',
    mode: 'same-origin'
  };

  if (!body) return ({ response: {error: 'Body is required'}, status: 400 });

  let bodyString = body;
  if (typeof body === 'object') {
    bodyString = JSON.stringify(body);
  }

  options.body = bodyString;

  const parseJson = async response => {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      return json;
    } catch (err) {
      return text;
    }
  };

  return await fetch(
    `/.netlify/functions/webdav?action=${btoa(action)}`,
    options
  ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

/**
 * BigCommerce Customer Login URL
 * 
 * Generates a Customer Login URL along with the JWT token
 * 
 * @param   {number} customerId     The customer ID the token is for
 * @param   {String} redirectTo     The URL to send the user after authentication
 * 
 * @return  {string}                Login URL
 *
    import { bcLoginUrl } from '../helpers/bigcommerce'

    bcLoginUrl(1, url);
 */
  async function bcLoginUrl(customerId, redirectTo) {
    const bcTime = await bcApi('time', 'GET', null, 2);
    const dateCreated = (bcTime.status === 200 && 'time' in bcTime.response) ? bcTime.response.time : Math.round((new Date()).getTime() / 1000);
    const payload = {
        "iss": process.env.BC_CLIENT_ID,
        "iat": dateCreated,
        "jti": v4(),
        "operation": "customer_login",
        "store_hash": process.env.BC_HASH,
        "customer_id": customerId,
        "redirect_to": redirectTo,
    }
    let token = jwt.sign(payload, process.env.BC_CLIENT_SECRET, {algorithm:'HS256'});
    return `${process.env.BC_PATH}/login/token/${token}`;
  }

function transformBCPost(post) {
  const API_STORE_HASH = process.env.BC_HASH;
  const thumbnail_uri = post.thumbnail_path
    ? `https://cdn11.bigcommerce.com/s-${API_STORE_HASH}${post.thumbnail_path}`
    : '';
  const post_url = post.url.replace(/\/bc-blog\//g, '');

  return {
    id: post.id,
    title: post.title,
    excerpt: post.summary,
    slug: `/bc-blog${post_url}`,
    content: post.body,
    author_name: post.author,
    author_uri: post.author, // TODO: need to find author uri
    published_date: post.published_date.date,
    thumbnail_path: thumbnail_uri,
    tags: post.tags.map(t => ({ name: t, uri: '' })),
    comments: []
    // categories: post.categories.nodes,
  };
}

export { 
  bcApi,
  webDav,
  bcLoginUrl,
  transformBCPost,
};
