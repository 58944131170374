// extracted by mini-css-extract-plugin
export var alternate = "Button-module--alternate--b811b";
export var button = "Button-module--button--b6aa0";
export var disabled = "Button-module--disabled--12b32";
export var flat = "Button-module--flat--55f34";
export var fullWidth = "Button-module--fullWidth--d5f41";
export var large = "Button-module--large--709da";
export var light = "Button-module--light--7bd74";
export var link = "Button-module--link--87315";
export var outline = "Button-module--outline--eefba";
export var primary = "Button-module--primary--05bd1";
export var round = "Button-module--round--b318c";
export var secondary = "Button-module--secondary--cefd4";
export var small = "Button-module--small--2357f";
export var smallest = "Button-module--smallest--b8f5a";