import React from 'react'

// Alphabetical order please

import Add from './add'
import AddReview from './addReview'
import AfterPayArrows from './afterpay-arrows'
import AfterPayLogo from './afterpay-logo'
import AfterPay from './afterpay'
import Album from './album'
import Alert from './alert'
import Amex from './amex'
import ArrowDown from './arrowDown'
import ArrowLeft from './arrowLeft'
import ArrowRight from './arrowRight'
import ArrowWithTail from './arrowWithTail';
import Bag from './bag'
import Bin from './bin'
import BrandingWatermark from './branding-watermark'
import Cable from './cable'
import Camera from './camera'
import CameraIndoor from './camera-indoor'
import CaretDown from './caretDown'
import Cart from './cart'
import CartAdd from './cartAdd'
import Check from './check'
import CheckFilled from './checkFilled'
import CheckOutline from './checkOutline';
import Click from './click'
import Close from './close'
import Currency from './currency'
import DesignServices from './design-services'
import Edit from './edit'
import Equaliser from './equaliser'
import Facebook from './facebook'
import FileDoc from './fileDoc'
import FileImg from './fileImg'
import FileMp3 from './fileMp3'
import FileMp4 from './fileMp4'
import FileOther from './fileOther'
import FilePdf from './filePdf'
import FilePpt from './filePpt'
import FileXls from './fileXls'
import FileZip from './fileZip'
import Filter from './filter'
import GiftBox from './gift-box'
import Hamburger from './hamburger'
import Heart from './heart'
import Headset from './headset'
import HelpCircle from './help-circle'
import House from './house'
import Humm from './humm'
import HummMin from './humm-min';
import Instagram from './instagram'
import Klarna from './klarna'
import KlarnaMin from './klarna-min';
import Link from './link'
import Linkedin from './linkedin'
import Latitude from './latitude'
import LatitudeIf from './latitude-if'
import Paypal from './paypal'
import PaypalMin from './paypal-min';
import PaypalColor from './paypal-color';
import MapMarker from './map-marker'
import MasterCard from './master-card';
import Minus from './minus';
import PaymentAdd from './paymentAdd'
import PaymentCheckout from './paymentCheckout'
import PaymentRepay from './paymentRepay'
import Phone from './phone'
import Pinterest from './pinterest'
import Play from './play'
import Purse from './purse'
import QuickView from './quickView'
import Search from './search'
import Star from './star'
import StarBlack from './star-black'
import StoreLocator from './store-locator'
import Swatch from './swatch'
import Tick from './tick'
import Tiktok from './tiktok'
import Translate from './translate'
import Truck from './truck'
import TruckMoving from './truckMoving'
import Twitter from './twitter'
import Tv from './tv'
import User from './user'
import View from './view'
import Visa from './visa';
import WhiteBag from './whiteBag'
import Youtube from './youtube'
import ZipLogo from './zip-logo'
import ZipMin from './zip-min';
import ZipMoneyLogo from './zip-money-logo';
import ZipPayLogo from './zip-pay-logo';

function Icon(props) {
  switch (props.symbol) {
    case 'add':
        return <Add />
    case 'addReview':
      return <AddReview />
    case 'afterpayArrows':
      return <AfterPayArrows />
    case 'afterPayLogo':
      return <AfterPayLogo />
    case 'afterPay':
      return <AfterPay />
    case 'album':
      return <Album />
    case 'alert':
      return <Alert />
    case 'amex':
      return <Amex />
    case 'arrowDown':
      return <ArrowDown />
    case 'arrowLeft':
      return <ArrowLeft />
    case 'arrowRight':
      return <ArrowRight />
    case 'arrowWithTail':
      return <ArrowWithTail />
    case 'bag':
      return <Bag />
    case 'bin':
      return <Bin />
    case 'brandingWatermark':
      return <BrandingWatermark />
    case 'cable':
      return <Cable />
    case 'camera':
      return <Camera />
    case 'cameraIndoor':
      return <CameraIndoor />
    case 'caretDown':
      return <CaretDown />
    case 'cart':
      return <Cart />
    case 'cartAdd':
      return <CartAdd />
    case 'check':
      return <Check />
    case 'checkFilled':
      return <CheckFilled />
    case 'checkOutline':
        return <CheckOutline />; 
    case 'click':
        return <Click />
    case 'close':
      return <Close />
    case 'currency':
      return <Currency />
    case 'designServices':
      return <DesignServices />
    case 'edit':
      return <Edit />
    case 'equaliser':
      return <Equaliser />
    case 'facebook':
      return <Facebook />
    case 'fileDoc':
      return <FileDoc />
    case 'fileImg':
      return <FileImg />
    case 'fileMp3':
      return <FileMp3 />
    case 'fileMp4':
      return <FileMp4 />
    case 'fileOther':
      return <FileOther />
    case 'filePdf':
      return <FilePdf />
    case 'filePpt':
      return <FilePpt />
    case 'fileXls':
      return <FileXls />
    case 'fileZip':
      return <FileZip />
    case 'filter':
      return <Filter />
    case 'giftbox':
      return <GiftBox />
    case 'hamburger':
      return <Hamburger />
    case 'heart':
      return <Heart />
    case 'headset':
      return <Headset />
    case 'helpCircle':
      return <HelpCircle />
    case 'house':
      return <House />
    case 'humm':
      return <Humm />
    case 'humm-min':
      return <HummMin />
    case 'instagram':
      return <Instagram />
    case 'klarna':
      return <Klarna />
    case 'klarna-min':
      return <KlarnaMin />
    case 'link':
      return <Link />
    case 'linkedin':
      return <Linkedin />
    case 'latitude':
      return <Latitude />
    case 'latitude-if':
      return <LatitudeIf />
    case 'paypal':
      return <Paypal />
    case 'paypal-min':
      return <PaypalMin />
    case 'paypal-color':
      return <PaypalColor />
    case 'mapMarker':
      return <MapMarker />
    case 'masterCard':
      return <MasterCard />
    case 'minus':
      return <Minus />
    case 'paymentAdd':
      return <PaymentAdd />
    case 'paymentCheckout':
      return <PaymentCheckout />
    case 'paymentRepay':
      return <PaymentRepay />
    case 'phone':
      return <Phone />
    case 'pinterest':
      return <Pinterest />
    case 'play':
      return <Play />
    case 'purse':
      return <Purse />
    case 'quickView':
      return <QuickView />
    case 'search':
      return <Search />
    case 'star':
      return <Star />
    case 'starBlack':
      return <StarBlack />
    case 'storeLocator':
      return <StoreLocator />
    case 'swatch':
      return <Swatch />
    case 'tick':
      return <Tick />
    case 'tiktok':
      return <Tiktok />
    case 'translate':
      return <Translate />
    case 'truck':
      return <Truck />
    case 'truckMoving':
      return <TruckMoving />
    case 'twitter':
      return <Twitter />
    case 'tv':
      return <Tv />
    case 'user':
      return <User />
    case 'view':
      return <View />
    case 'visa':
      return <Visa />
    case 'whiteBag':
      return <WhiteBag />
    case 'youtube':
      return <Youtube />
    case 'zipLogo':
      return <ZipLogo />
    case 'zip-min':
      return <ZipMin />
    case 'zipMoneyLogo':
      return <ZipMoneyLogo />
    case 'zipPayLogo':
      return <ZipPayLogo />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
