import React from 'react'

const Tiktok = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_665_185)">
        <path d="M13.5 0H16.47C16.686 1.0725 17.28 2.4255 18.3225 3.768C19.3425 5.0835 20.6955 6 22.5 6V9C19.8705 9 17.895 7.779 16.5 6.2565V16.5C16.5 17.9834 16.0601 19.4334 15.236 20.6668C14.4119 21.9001 13.2406 22.8614 11.8701 23.4291C10.4997 23.9968 8.99168 24.1453 7.53683 23.8559C6.08197 23.5665 4.7456 22.8522 3.6967 21.8033C2.64781 20.7544 1.9335 19.418 1.64411 17.9632C1.35472 16.5083 1.50325 15.0003 2.07091 13.6299C2.63856 12.2594 3.59986 11.0881 4.83323 10.264C6.0666 9.43987 7.51664 9 9 9V12C8.10999 12 7.23996 12.2639 6.49994 12.7584C5.75992 13.2529 5.18314 13.9557 4.84255 14.7779C4.50195 15.6002 4.41284 16.505 4.58647 17.3779C4.7601 18.2508 5.18869 19.0526 5.81802 19.682C6.44736 20.3113 7.24918 20.7399 8.1221 20.9135C8.99501 21.0872 9.89981 20.9981 10.7221 20.6575C11.5443 20.3169 12.2472 19.7401 12.7416 19.0001C13.2361 18.26 13.5 17.39 13.5 16.5V0Z" fill="currentColor"/>
    </g>
    <defs>
        <clipPath id="clip0_665_185">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
    </svg>
)

export default Tiktok