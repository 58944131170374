import { navigate } from 'gatsby';
import { useState, useEffect } from 'react';

/**
 * Track recently viewed products
 * 
 * Handles tracking of product views and stores product IDs in session for display on the site
 * 
 * @param   {Number} productId       The prodoct ID to track
 * 
    import { trackRV } from '../helpers/general'

    trackRV(productId).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
function trackRV(productId) {
  const sessionKey = '__trackRV';
  const displayAmount = 10;

  if (typeof sessionStorage !== 'undefined') {
    const recentlyViewed = getStorage(sessionKey);
    let recentlyViewedArr = [];

    if (recentlyViewed !== null) {
      recentlyViewedArr = JSON.parse(recentlyViewed);
    }

    const existIndex = recentlyViewedArr.indexOf(productId);

    if (existIndex > -1) {
      recentlyViewedArr.splice(existIndex, 1);
    }

    recentlyViewedArr.unshift(productId);

    if (recentlyViewedArr.length > displayAmount) {
      recentlyViewedArr.pop();
    }

    setStorage(sessionKey, JSON.stringify(recentlyViewedArr), true);
  }
}

/**
 * Get local / session / cookie storage information
 * 
 * Handles retrieving browser storage information functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { infoStorage } from '../helpers/general'

    infoStorage('theKey')
 */
function infoStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return {storage: 'sessionStorage', value: sessionValue};
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return {storage: 'localStorage', value: localValue};
      }
    }
  } catch (e) {
    infoCookie(key);
  }

  return null;
}

/**
 * Get cookie storage information
 * 
 * Handles browser storage information functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { infoCookie } from '../helpers/general'

    infoCookie('theKey')
 */
function infoCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return {storage: 'cookie', value: c.substring(name.length, c.length)};
      }
    }
    return null;
  }
}

/**
 * Get local / session / cookie storage
 * 
 * Handles retrieving browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { getStorage } from '../helpers/general'

    getStorage('theKey')
 */
function getStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return sessionValue;
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return localValue;
      }
    }
  } catch (e) {
    getCookie(key);
  }

  return null;
}

/**
 * Get cookie storage
 * 
 * Handles browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { getCookie } from '../helpers/general'

    getCookie('theKey')
 */
function getCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
}

/**
 * Set local / session / cookie storage
 * 
 * Handles setting browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 * 
    import { setStorage } from '../helpers/general'

    setStorage('theKey', 'theValue', true)
 */
function setStorage(key, value, expire) {
  try {
    // Use Local / Session storage
    if (typeof sessionStorage !== 'undefined' && expire) {
      sessionStorage.setItem(key, value);
    } else if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, value);
    }
  } catch (e) {
    // Use Cookies
    setCookie(key, value, expire);
  }
}

/**
 * Set cookie storage
 * 
 * Handles setting browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 * 
    import { setCookie } from '../helpers/general'

    setCookie('theKey', 'theValue', true)
 */
function setCookie(key, value, expire) {
  if (typeof document !== 'undefined') {
    let exDays = 365;
    if (expire) {
      exDays = 1;
    }
    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=${value};${expires};path=/`;
  }
}

/**
 * Remove local / session / cookie storage
 * 
 * Handles removing browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { removeStorage } from '../helpers/general'

    removeStorage('theKey')
 */
function removeStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        sessionStorage.removeItem(key);
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        localStorage.removeItem(key);
      }
    }
  } catch (e) {
    // Use Cookies
    removeCookie(key);
  }
}

/**
 * Remove cookie storage
 * 
 * Handles removing browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { removeCookie } from '../helpers/general'

    removeCookie('theKey')
 */
function removeCookie(key) {
  if (typeof document !== 'undefined') {
    const d = new Date();
    d.setTime(d.getTime() - 1 * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=;${expires};path=/`;
  }
}

/**
 * Validate email format
 * 
 * Checks the provided email address and validates its format
 * 
 * @param   {String} email  The email address
 * 
    import { validateEmail } from '../helpers/general'

    validateEmail(email)
 */
function validateEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
 * Is value numeric
 * 
 * Determine whether variable is a number
 * 
 * @param {*} str 
 *
    import { isNumeric } from '../helpers/general'

    isNumeric(value)
 */
function isNumeric(str) {
  if (['string', 'number'].indexOf(typeof str) === -1) return false // we only process strings and numbers!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

/**
 * Get ordinal for date number
 * 
 * Given a date number, this will return the suffix for a date
 * 
 * @param   {Number} n  The date
 * 
    import { dateOrdinal } from '../helpers/general'

    dateOrdinal(25)
 */
function dateOrdinal(n) {
  return n > 3 && n < 21
    ? 'th'
    : n % 10 === 2
    ? 'nd'
    : n % 10 === 2
    ? 'nd'
    : n % 10 === 3
    ? 'rd'
    : 'th';
}

/**
 * Get value from querystring
 *
 * @param {string} key The key of the value you're looking to retrieve
 * @returns {string|false} the value or false if it doesn't exist
 */
 function getQuerystringValue(key) {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);

  return value || false;
}

/**
 * Get slug of the product from given uri
 *
 * @param {string} uri the url of the product
 * example: http://your-store-url.mybigcommerce.com/your-product/
 * @returns {string} slug of the product
 * example: /product/your-product/
 */
function getUriFromURL(urlStr = '') {
  if (urlStr.startsWith('/product')) {
    return urlStr;
  }

  const slug = urlStr
    .split('/')
    .filter(x => x)
    .pop();
  if (slug) {
    return `/product/${slug}/`;
  }
  
  return '';
}

/**
 * Calculate read time
 *
 * Pass content to be counted and return a minute value for the read time length
 *
 * @param   {string} content The content to be counted
 *
 * import { readTime } from '../helpers/general'
 *
 * readTime(content)
 */
function readTime(content) {
  const foundImages = (content.match(/<img/g) || []).length;
  const text = content.replace(/(<([^>]+)>)/gi, '');
  const foundWords = text.split(' ').length;

  let secs = (foundWords / 275) * 60;
  let addSecs = 12;
  for (let i = 0; i < foundImages; i++) {
    secs += addSecs;
    addSecs--;
    if (addSecs < 3) addSecs = 3;
  }
  const minutes = Math.ceil(secs / 60);

  return minutes;
}

/**
 * Fetch title
 * 
 * Determine which title for a product to use
 * 
 * @param   {string} bcData   The BC product title
 * @param   {string} akData   The string to split by
 * 
 * import { fetchTitle } from '../helpers/general'
 * 
 * fetchTitle(bcData, akData)
 * 
 * @returns {string} title
 */
 function fetchTitle(bcData, akData) {
    let title = '';
    if (bcData && bcData.detail && bcData.detail.values.webName) {
      title = bcData.detail.values.webName;
    } else if (akData && akData.values.webName) {
      title = akData.values.webName[0].data;
    } else {
      if(bcData.name !== undefined) {
        const splitTitle = dissectTitle(bcData.name, ' (');
        title = splitTitle[0];
      }
    }

    return title;
}

/**
 * Dissect title
 * 
 * Split out title to reveal the design type from the product name
 * 
 * @param   {string} productTitle   The product title
 * @param   {string} splitBy        The string to split by
 * 
 * import { dissectTitle } from '../helpers/general'
 * 
 * dissectTitle(productTitle, splitBy)
 * 
 * @returns {array} splitTitle
 */
function dissectTitle(productTitle, splitBy) {
    let titleParts = [''];
    if(productTitle !== undefined) {
       titleParts = productTitle.split(splitBy);
    }
    
    return titleParts;
}

/**
 * Window Dimensions
 *
 * Gets values from window object for the `useWindowDimensions` function
 *
 */
function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  return { width: 0, height: 0 };
}

/**
 * Window Dimensions
 * 
 * Provide on the fly window dimensions
 * 
    import { useWindowDimensions } from '../helpers/general'

    const { width } = useWindowDimensions()
 */
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }

    return;
  }, []);

  return windowDimensions;
}

/**
 * useScript
 *
 * Provides a way to include a script on the frontend
 * 
    import { useScript } from '../helpers/general'

    useScript('https://path.to/script', 'functionOrObject', functionOnceLoaded);
 */
function useScript(url, functionOrObject, callback) {
  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const existing = functionOrObject in window;
      const loading = window.scriptsLoading && window.scriptsLoading.indexOf(functionOrObject) > -1;
      if (!existing && !loading) {
        if (!('scriptsLoading' in window)) {
          window.scriptsLoading = [];
        }
        window.scriptsLoading.push(functionOrObject);
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => { 
          if (callback) {
            callback();
          }
        };

        return () => {
          document.body.removeChild(script);
        }
      }

      if (existing && callback) {
        callback();
      }
    }
  }, [url, functionOrObject, callback])
}

/**
 * Decode unicode characters
 * 
 * Provides a way to decode a string containing unicode characters for logic purposes
 * 
    import { decodeUnicode } from '../helpers/general'

    decodeUnicode(content);
 */
function decodeUnicode(text) {
  var map = {
      '&amp;': '&',
      '&#038;': "&",
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#039;': "'",
      '&#8217;': "'",
      '&#8216;': "'",
      '&#8211;': "-",
      '&#8212;': "-",
      '&#8230;': "…",
      '&#8221;': '"',
      '&#8243;': '"'
  };
  // console.log("Decoding", text);
  return text.replace(/&[\w\d#]{2,5};/g, function(m) { return map[m]; });
}

function rewriteShippingOptionLabel(label, hideType = false) {
  let modLabel = label;
  if (hideType) {
    modLabel = modLabel.replace('Business:', '');
    modLabel = modLabel.replace('Residential:', '');
  }
  modLabel = modLabel.replace('Videopro Driver (Free Freight)', 'Free Freight');
  return modLabel;
}

function validatePhoneNumber(number) {
  const regex = /^[- +()]*[0-9][- +()0-9]*$/;

  return regex.test(number);
}

function dataLayerPush(eventName, extraData, productData, linkTo, listObj) {
  // Ensure window is available before moving forward
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];

    // console.log('dataLayer Data', extraData, productData, linkTo);

    const object = {
      ...extraData,
      event: eventName
    }

    let productsObject = false;

    let productDataCategories = {};

    if (productData && productData.categories) {
      productData.categories.forEach((category, i) => {
          productDataCategories[`item_category${i}`] = category.name
      });
    }

    const productObject = product => ({
      item_id: product.sku,
      // id: product.entityId || product.entity_id || product.productId || product.product_id || product.id || 0,
      item_name: product.title || product.name || '',
      discount: product.price > product.calculated_price ? product.price - product.calculated_price : 0,
      item_variant: product.name && product.name.indexOf(' - ') > -1 ? product.name.split(' - ').pop() : product.title && product.title.indexOf(' - ') > -1 ? product.title.split(' - ').pop() : undefined,
      ...productDataCategories,
      item_list_id: listObj ? listObj.bigcommerce_id : undefined,
      item_list_name: listObj ? listObj.name : undefined,
      price: product.price || product.sale_price || 0,
      currency: 'AUD',
      // category: product.categories,
      quantity: product.quantity || 1,
    });

    if (productData) {
      const products = !Array.isArray(productData) ? [productData] : productData;
      productsObject = [...products.map(product => productObject(product))]
    }

    productsObject = JSON.parse(JSON.stringify(productsObject));

    switch (eventName) {
      case 'view_item':
        if (productsObject) {
          object.currency = 'AUD'
          object.value = productsObject[0].price;
          object.items = productsObject;
        }
        break;

      case 'view_item_list':
          if (productsObject) {
            object.items = productsObject;
          }
          break;

      case 'view_cart':
        if (productsObject) {
          object.items = productsObject;
        }
        break;

      case 'add_to_cart':
        if (productsObject) {
          object.currency = 'AUD';
          object.value = productsObject[0].price;
          object.items = productsObject;
        }
        break;

      case 'remove_from_cart':
        if (productsObject) {
          object.currency = 'AUD';
          object.value = productsObject[0].price;
          object.items = productsObject;
        }
        break;

      default:
        // do nothing
      break;
    }

    // Check if GTM is loaded
    if (linkTo) {
      if (typeof window.google_tag_manager !== 'undefined') {
        object.eventCallback = () => {
          if (linkTo.startsWith('http')) {
            document.location = linkTo;
          } else {
            navigate(linkTo)
          }
        };
      } else {
        // Handler for when GTM is not present
        if (linkTo.startsWith('http')) {
          document.location = linkTo;
        } else {
          navigate(linkTo)
        }
      }
    }

    // console.log('Pushing DataLayer: ', object);
    window.dataLayer.push(object);
  }
}

export {
  trackRV,
  infoStorage,
  infoCookie,
  getStorage,
  getCookie,
  setStorage,
  setCookie,
  removeStorage,
  removeCookie,
  validateEmail,
  validatePhoneNumber,
  isNumeric,
  dateOrdinal,
  getQuerystringValue,
  readTime,
  getUriFromURL,
  fetchTitle,
  dissectTitle,
  useWindowDimensions,
  useScript,
  decodeUnicode,
  rewriteShippingOptionLabel,
  dataLayerPush
};
