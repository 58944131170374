/**
 * Algolia API Helper
 * 
 * Handles sending click and convertion events to Algolia Insights
 *
 * */

import algoliasearch from 'algoliasearch'
import { googleApi } from '../google';
import { bcApi } from '../bigcommerce';

// const bcApi = require('./bigcommerce');
require('dotenv').config();

const moment = require('moment');
const aa = require('search-insights')
// const algoliasearch = require('algoliasearch')
const appId = process.env.ALGOLIA_APP_ID;
const apiKey = process.env.ALGOLIA_SEARCH_API_KEY;
const adminApiKey = process.env.ALGOLIA_API_KEY;
const indexName = process.env.ALGOLIA_INDEX_NAME;
// const mostPopularIndex = 'Production_most_popular';

export const CLICK_AFTER_SEARCH = 'Product View from Search'
export const CLICK_ADD_CART = 'Product Added to Cart'


function startClickAfterSearch(data) {
  aa('init', { appId, apiKey })

  aa('clickedObjectIDsAfterSearch', {
    userToken: data.userToken,
    index: data.index,
    eventName: data.eventName,
    queryID: data.queryID,
    objectIDs: [data.objectID],
    positions: [data.position]
  })

  updateTrackingAttribute(data, 'numberOfClicks');
}

function convertClickedObjects(data) {
  aa('init', { appId, apiKey })

  aa('convertedObjectIDsAfterSearch', {
    userToken: data.userToken,
    index: data.index,
    eventName: data.eventName,
    queryID: data.queryID,
    objectIDs: [data.objectID]
  })

  updateTrackingAttribute(data, 'numberOfConvert');
}

// Update of attribute to use for sorting of index records for 'Most Popular' based on clicks
function updateTrackingAttribute(data, attribute) {
  const client = algoliasearch(appId, adminApiKey)
  const index = client.initIndex(indexName);

  index.partialUpdateObject({
    [attribute]: {
      _operation: 'Increment',
      value: 1,
    },
    objectID: data.objectID,
  })
  .then(({ objectIDs }) => {
    // Object ID attribute updated
    // console.log(objectIDs);
  });
}

async function uploadGaStats() {
  const client = algoliasearch(appId, adminApiKey)
  const index = client.initIndex(indexName);

  const now = moment().format('YYYY-MM-DD @ HH:mm');
  const priorDate = moment().subtract(30, 'days').format('YYYY-MM-DD');

  const reportMetrics = {
    dateRanges: [
        {
            "startDate": moment().subtract(30, 'days').format('YYYY-MM-DD'),
            "endDate": moment().format('YYYY-MM-DD')
        }
    ],
    dimensions: [
        {
            "name": "itemName"
        },
        {
            "name": "itemId"
        }
    ],
    metrics: [
        {
            "name": "itemsViewed"
        },
        {
            "name": "itemsAddedToCart"
        },
        {
            "name": "itemsPurchased"
        },
        {
            "name": "itemRevenue"
        }
    ],
  };

  await googleApi('runReport', 'POST', reportMetrics).then(async (response) => {
    // console.log(response);
    if (response.status === 200 && response.response.rowCount > 0) {
      const data = response.response;
      let mappedGaData = [];
      const skus = [];
      data.rows.map(rows => {
        if (rows.dimensionValues[1].value !== '(not set)') {
          skus.push(rows.dimensionValues[1].value)

          mappedGaData[rows.dimensionValues[1].value] = {
            'itemName': rows.dimensionValues[0].value,
            'itemsViewed': parseInt(rows.metricValues[0].value),
            'itemsAddedToCart': parseInt(rows.metricValues[1].value),
            'itemsPurchased': parseInt(rows.metricValues[2].value),
            'itemRevenue': parseFloat(rows.metricValues[3].value),
          }
        }

        return rows;
      })

      if (skus && skus.length > 0) {
        const getBcProducts = (skus) => {
          return new Promise(async (res) => {
            const chunks = [];

            for (let i = 0; i < skus.length; i += 100) {
              const chunk = skus.slice(i, i + 100);
              chunks.push(chunk);
            }

            const products = [];

            const chunkItems = chunks.map(async (theSkus) => {
              return bcApi(`catalog/products?limit=250&include_fields=sku,date_created,name&sku:in=${theSkus.join(',')}`, 'GET', null).then(response => {
                if ('response' in response && response.response.data.length > 0) {
                    products.push(...response.response.data);
                }
              });
            })

            Promise.all(chunkItems).then(() => {
              res(products);
            });
          });
        }

        const allProducts = await getBcProducts(skus);

        const algoliaMapped = [];
        allProducts.map((prod) => {
          const gaData = mappedGaData[prod.sku] ? mappedGaData[prod.sku] : null;

          if (gaData) {
            const data = {
              objectID: prod.id,
              last_ga_update: now,
              new_product: moment(prod.date_created).isSameOrAfter(priorDate) ? 1 : 0,
              ...mappedGaData[prod.sku]
            };

            algoliaMapped.push(data)
          }

          return prod;
        })

        index.partialUpdateObjects(algoliaMapped, {
          createIfNotExists: false
        });

        console.log('Algolia details updated')
        return {success: true, message: 'Algolia details updated'}
      }
    }
  });
}

async function tryGaStats () {
  const parseJson = async response => {
    const text = await response.text();
    try {
        const json = JSON.parse(text);
        return json;
    } catch (err) {
        return text;
    }
  };

  return await fetch(
    `/.netlify/functions/gaToAlgolia-scheduled?endpoint=${btoa('runReport')}`,
    {   
      method: 'GET',
      credentials: 'same-origin',
      mode: 'same-origin',
    }
  ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

export { 
  startClickAfterSearch,
  convertClickedObjects,
  uploadGaStats,
  tryGaStats
};