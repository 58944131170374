import React, { createContext, useContext, useState, useEffect } from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
import GraphQLContext from './GraphQLProvider';
import { bcApi } from '../helpers/bigcommerce'
import { getStorage, removeStorage, setStorage, infoStorage } from '../helpers/general'

const AuthContext = createContext();

const initialState = {
    userChecked: false,
    isLoggedIn: false,
    customerId: 0,
    email: '',
    support: {},
  };

export const AuthProvider = ({ children, bcPath, clientId }) => {
  const graphqlCxt = useContext(GraphQLContext);
  const graphqlQuery = graphqlCxt && graphqlCxt.query
  const [state, setState] = useState(initialState);

  /**
   * This data keeps changing if a customer group has discounts assigned or not. 
   * If some are assigned, there is an additional level with `_1` under discount_rules.
   * GraphQL is doesn't expect schemas to change dynamically like that which is a problem here.
   */
//    const customerGroupData = useStaticQuery(graphql`
//    query {
//      allBigCommerceCustomerGroups {
//        edges {
//          node {
//            name
//            id: bigcommerce_id
//            discount_rules {
//              amount
//              method
//              type
//            }
//            is_default
//            is_group_for_guests
//          }
//        }
//      }
//    }
//  `)
 const customerGroups = {};
//  customerGroupData?.allBigCommerceCustomerGroups.edges.map(group => {
//    customerGroups[group.node.id] = group.node;

//    return true;
//  });

  const addSupport = (key, value) => {
    const support = state.support;
    if (!(key in support)) {
      support[key] = value;
      setState({...state, support});
    }
  }

  const checkLoggedIn = () => {
    const sessionData = infoStorage('_loggedIn');
    if (sessionData) {
        // Check timestamp, force login after a day
        const userObject = JSON.parse(atob(sessionData.value));
        const date = new Date();
        date.setDate(date.getDate() - 1);
        if ('timestamp' in userObject) {
            // console.log(date.getTime(), userObject.timestamp)
            if (date.getTime() < userObject.timestamp) {
                // console.log("Fetching user");
                userObject.timestamp = Date.now();
                setStorage('_loggedIn', btoa(JSON.stringify(userObject)), sessionData.storage === 'sessionStorage' ? true : false);
                fetchLogin(userObject.customerId)
            } else {clearStorage();setState({...state, userChecked: true})}
        } else {clearStorage();setState({...state, userChecked: true})}
    } else {clearStorage();setState({...state, userChecked: true, onFetchLogin: false})}
  }

  // eslint-disable-next-line
  useEffect(() => checkLoggedIn(), []);

  const getCustomerGroup = (customerGroupId) => {
    if (customerGroups && customerGroupId in customerGroups) {
      return customerGroups[customerGroupId].name;
    }

    return 'guest';
  } 

  const fetchLogin = async (id) => {
    setState({...state, onFetchLogin: true})
    return new Promise((resolve) => {
      bcApi(`customers?id:in=${id}&include=formfields,addresses`).then(({response}) => {
        setState({
          ...state,
          userChecked: true,
          customerId: response.data[0].id,
          email: response.data[0].email,
          customerGroup: getCustomerGroup(response.data[0].customer_group_id),
          object: response.data[0],
          isLoggedIn: true,
          onFetchLogin: false
        })
        resolve(response)
      })
      .catch(error => {
      //   console.log('Error:', error)
        setState({...initialState, userChecked: true, onFetchLogin: false})
        resolve(error)
      });
    })
  }

  const refreshData = () => {
    if (state.isLoggedIn) {
      fetchLogin(state.customerId);
    }
  }

  const afterAuth = async (customerId) => {
    const afterAuthCalls = getStorage('_afterAuth');
    if (afterAuthCalls) {
      const afterAuthCallsObject = JSON.parse(afterAuthCalls);
      if (afterAuthCallsObject.action === 'saveWishlist') {
        // console.log("_afterAuth should be removed");
        removeStorage('_afterAuth')
        const actionResult = await state.support.wishlist.saveWishlist(afterAuthCallsObject.name, true, customerId)
        return actionResult
      }
    }

    return false
  }

  const logout = () => {
      // TODO: Update to work with more secure process
      setState(initialState)
      clearStorage(true);

      bcApi('carts').then(async ({response}) => {
        if (response && 'data' in response && 'id' in response.data) {
          await bcApi(`carts/${response.data.id}`, 'PUT', {'customer_id': 0});
        }
        graphqlQuery(`
          mutation Logout {
            logout {
              result
            }
          }
        `).then(response => {
          if (typeof window !== 'undefined') {
            window.location = '/';
          }
        });
      });
  }

  const clearStorage = (fullClear) => {
    removeStorage('_loggedIn');
    removeStorage('_isWholesale');
    removeStorage('_isPending');
    if (fullClear) {
      removeStorage('__jammcd');
    }
  }

  /***** SECOND AUTH ATTEMPT - Works validating user with GraphQL Mutation however session retaining is hacked together insecure *****/
  const checkPassword = (email, password) => {
    return new Promise((res) => {
      graphqlQuery(`
        mutation login($email: String!, $password: String!) {
          login(email: $email, password: $password) {
            result
          }
        }
      `, { email, password }).then(response => res(response));
    })
  }

  const login = (email, password, remember) => {
    return new Promise((res, rej) => {
      checkPassword(email, password).then(response => {
        // console.log("Login attempt:", response);
        if (response.data && response.data.login.result === 'success') {
          // Confirmed successful login
          // TODO: Replace temp work around with more secure method
          // If remember logged in, then no expire, else expire login session
          let expire = remember ? false : true;
          res(fetchAndSetCustomer(email,expire));

        } else {
          rej("Incorrect username and/or password")
        }
      });
    });
  }

  const fetchAndSetCustomer = (email, expire) => {
    return new Promise((res, rej) => {
      bcApi(`customers?email:in=${encodeURIComponent(email)}&include=formfields,addresses`).then(async ({response}) => {
        // console.log('Response:', response)
        if (response.data) {
          const customerId = response.data[0].id;
          setState({...state, customerId, email: response.data[0].email, object: response.data[0]})
          setStorage('_loggedIn', btoa(JSON.stringify({customerId, timestamp: Date.now(), userChecked: true})), expire)

          const afterAuthAction = await afterAuth(customerId);
          // console.log("After login triggered", afterAuthAction)

          if (typeof window !== 'undefined' && typeof document !== 'undefined' && !afterAuthAction) {
            // console.log("Redirecting normally");
            const browserLastPage = !document.referrer.startsWith(process.env.SITE_URL) ? process.env.SITE_URL : (document.referrer.indexOf('logout') ? process.env.SITE_URL : document.referrer);
            const userLastPage = getStorage('lastPage');
            const forcedPage = getStorage('forcedPage');
            const returnUrl = forcedPage || userLastPage || browserLastPage;
            removeStorage('forcedPage');
            window.location = returnUrl;
            // bcLoginUrl can't be used as the redirect has to be relative to the BC store URL.
            // bcLoginUrl(customerId, returnUrl).then(loginUrl => {
            //   console.log("Logged in promise", loginUrl);

            //   window.location = redirectUrl
            // });
          }
          res("Success");
        } else {
          setState({...initialState, userChecked: true})
          // TODO: throw error feedback to page
          rej("Failed to fetch");
        }
      })
      .catch(error => {
        // console.log('Error:', error)
        setState({...initialState, userChecked: true})
        // TODO: throw error feedback to page
        rej("Failed to fetch");
      });
    });
  }

  // const getCurrentCustomer = () => {
  //   fetch(`${bcPath}/customer/current.jwt?app_client_id=${clientId}`,
  //     {
  //       method: 'GET',
  //     }
  //   )
  //     .then(res => res.json())
  //     .then(response => {
  //       console.log('Response:', response)
  //     });
  // }
  /***** END SECOND AUTH ATTEMPT *****/

  /***** FIRST AUTH ATTEMPT - Works but uses deprecated BC Endpoint, also insecure *****/
  //    const login = (email, password) => {
  //    bcApi(`customers&email:in=${email}`).then(({response}) => {
  //       // console.log('Response:', response)
  //       setState({...state, customerId: response.data[0].id, email: response.data[0].email, object: response.data[0]})
  //       validate(response.data[0].id, password)
  //     })
  //     .catch(error => {
  //       // console.log('Error:', error)
  //       setState({...initialState, userChecked: true})
  //       // TODO: throw error feedback to page
  //     });
  // };

  // const validate = (customerId, password) => {
  //    const bcApiBody = JSON.stringify({ password });
  //    bcApi(`loginValidate&customerId=${customerId}`, 'POST', bcApiBody).then(({response}) => {
  //       if (response.success) {
  //           setState({...state, isLoggedIn: response.success});
  //           // TODO: Setup secured authentication storage
  //           if (typeof sessionStorage !== 'undefined') {
  //               sessionStorage.setItem('_loggedIn', btoa(JSON.stringify({customerId, timestamp: Date.now(), userChecked: true})))
  //           }
  //           if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  //               window.location = document.referrer;
  //           }
  //       } else {
  //         setState({...initialState, userChecked: true})
  //         // TODO: throw error feedback to page
  //       }
  //     })
  //     .catch(error => {
  //       // console.log('Error:', error)
  //       setState({...initialState, userChecked: true})
  //       // TODO: throw error feedback to page
  //     });
  // };
  /***** END FIRST AUTH ATTEMPT *****/

  const forgotPassword = (email) => {
    return new Promise((res) => {
      fetch(`${bcPath}/login.php?action=send_password_email`, {
        body: `email=${email}`,
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "post",
      }).then(result => {
        // console.log(result);
        res(true)
      }).catch(error => {
        // Endpoint returns failed however the process is actually successful. As such, we can't effectively
        // console.log(error);
        res(true)
      });
    });
  }

  const changePassword = (fields, customerId, token) => {
    const bcApiBody = JSON.stringify([fields]);
    fetch(`${bcPath}/login.php?action=save_new_password&c=${customerId}&t=${token}`, {
      body: bcApiBody,
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "post",
    }).then(result => {
      console.log(result);
    });
  }

  const signup = (fields) => {
    return new Promise((res, rej) => {
      const fieldsUpdated = fields;
      if ('firstName' in fieldsUpdated) {
        fieldsUpdated.first_name = fields.firstName;
      }
      if ('lastName' in fieldsUpdated) {
        fieldsUpdated.last_name = fields.lastName;
      }
      if ('address1' in fieldsUpdated) {
        fieldsUpdated.addresses = [{
          first_name: fields.firstName,
          last_name: fields.lastName,
          company: fields.company,
          address1: fields.address1,
          address2: fields.address2,
          city: fields.city,
          state_or_province: fields.stateOrProvince,
          postal_code: fields.postalCode,
          country_code: fields.countryCode,
          phone: fields.phone,
          address_type: 'commercial'
        }]
      }
      
      const customFields = [];
      Object.keys(fieldsUpdated).map(fieldKey => {
        if (fieldKey.startsWith('custom_')) {
          const key = fieldKey.replace('custom_', '');
          customFields.push({
            name: key,
            value: fieldsUpdated[fieldKey],
          });
          delete fieldsUpdated[fieldKey];
        }

        return true;
      });
      const bcApiBody = JSON.stringify([fieldsUpdated]);
      bcApi('customers', 'POST', bcApiBody).then(({response}) => {
        if (response.data) {
            const customer = response.data[0];

            const updatedCustomFields = customFields.map(field => {
              const currentField = field;
              currentField.customer_id = customer.id;
              return currentField;
            });

            bcApi('customers/form-field-values', 'PUT', updatedCustomFields).then(response => {

              const netlifyFormFields = {
                first_name: fields.firstName,
                last_name: fields.lastName,
                email: fields.email,
                system_note: 'Account registration submission',
                'form-name': 'signup'
              }

              fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode(netlifyFormFields),
              })
              .then(() => {
                setState({...state, isLoggedIn: true, customerId: customer.id, email: customer.email, object: customer});
                // TODO: Setup secured authentication storage
                setStorage('_loggedIn', btoa(JSON.stringify({customerId: customer.id, timestamp: Date.now(), userChecked: true})))
                
                const afterAuthAction = afterAuth(customer.id);
  
                if (typeof window !== 'undefined' && typeof document !== 'undefined' && !afterAuthAction) {
                  const returnUrl = !document.referrer.startsWith(process.env.SITE_URL) ? process.env.SITE_URL : (document.referrer.indexOf('logout') ? process.env.SITE_URL : document.referrer);
                  window.location = returnUrl;
  
                  // bcLoginUrl can't be used as the redirect has to be relative to the BC store URL.
                  // bcLoginUrl(customer.id, returnUrl).then(redirectUrl => {
                  //   console.log("Signed in promise", redirectUrl);
                  //   window.location = redirectUrl
                  // });
                }
                res("Success");
              })
              .catch((error) => console.log(error))
            });
        } else {
          setState({...initialState, userChecked: true});
          const messageKeys = Object.keys(response.errors);
          let message = response.errors[messageKeys[0]].split(':');
          message = message[message.length - 1].trim();
          message = message.charAt(0).toUpperCase() + message.slice(1);
          // console.log(response.errors, message, messageKeys);
          if (message === "Error.path.missing") {
            message = "Multiple fields are empty. Please fill all available fields in.";
          }
          rej(message);
        }
      })
      .catch(error => {
        // console.log('Error:', error)
        setState(initialState)
        rej(error);
      });
    });
  }

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  return (
    <AuthContext.Provider value={{
        state,
        setState,
        addSupport,
        refreshData,
        login,
        logout,
        signup,
        checkPassword,
        forgotPassword,
        changePassword
    }}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;