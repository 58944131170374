import React from 'react';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import Button from '../../components/atoms/Button/Button';
import Icon from '../../components/atoms/Icon/Icon';


/**
 * Custom Toast Functions
 */
function success(message) {
    toast.success(message, {
        icon:
          <div style={{width: '20px', height: '20px', color: 'var(--standard-white'}}>
            <Icon symbol={'check'}/>
          </div>
      });
}

function successAddToCart(message) {
  const Msg = () => {
    return(
      <div>
        <div style={{display: 'flex', gap: '16px'}}>
          <div style={{width: '20px', height: '20px', color: 'var(--standard-white'}}>
            <Icon symbol={'check'}/>
          </div>
          <p style={{marginBottom: '8px'}}>{message}</p>
        </div>
        <Button 
          fullWidth 
          type={'button'} 
          onClick={() => navigate('/confirm-order')} 
          level={'primary'}>
          Checkout
        </Button>
      </div>
    )
  }

  toast(<Msg></Msg>)
}

function error(message) {
    toast.error(message);
}

export { success, error, successAddToCart };