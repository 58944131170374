import React from 'react'

const LatitudeIf = () => (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1030 200">
    <g id="Artwork">
        <g>
            <g>
            <path class="cls-1" fill="#0046aa" d="m749.98,150.85c42.56,24.57,90.86,37.52,140.01,37.52s97.44-12.94,140.01-37.52V16.29h-280.02v134.56Z"/>
            <g>
                <path class="cls-2" fill="#fff" d="m788.33,43.13h5.48v38.37h-5.48v-38.37Z"/>
                <path class="cls-2" fill="#fff" d="m832.12,64.78v16.72h-5.26v-16.11c0-5.7-2.85-8.5-7.84-8.5-5.59,0-9.21,3.34-9.21,9.65v14.96h-5.26v-29.05h5.04v4.38c2.14-2.96,5.86-4.66,10.47-4.66,7.07,0,12.06,4.06,12.06,12.61Z"/>
                <path class="cls-2" fill="#fff" d="m858.21,79.8c-1.59,1.37-4,2.03-6.36,2.03-5.86,0-9.21-3.23-9.21-9.1v-15.95h-4.93v-4.33h4.93v-6.36h5.26v6.36h8.33v4.33h-8.33v15.73c0,3.12,1.64,4.88,4.55,4.88,1.53,0,3.01-.49,4.11-1.37l1.64,3.78Z"/>
                <path class="cls-2" fill="#fff" d="m889.73,68.72h-23.57c.66,5.1,4.77,8.5,10.52,8.5,3.4,0,6.25-1.15,8.39-3.51l2.9,3.4c-2.63,3.07-6.63,4.71-11.46,4.71-9.37,0-15.62-6.19-15.62-14.85s6.19-14.8,14.58-14.8,14.36,6.03,14.36,14.96c0,.44-.05,1.1-.11,1.59Zm-23.57-3.78h18.64c-.55-4.88-4.22-8.33-9.32-8.33s-8.77,3.4-9.32,8.33Z"/>
                <path class="cls-2" fill="#fff" d="m912.75,52.17v5.1c-.44-.06-.82-.06-1.21-.06-5.65,0-9.15,3.45-9.15,9.81v14.47h-5.26v-29.05h5.04v4.88c1.86-3.4,5.48-5.15,10.58-5.15Z"/>
                <path class="cls-2" fill="#fff" d="m945.09,68.72h-23.57c.66,5.1,4.77,8.5,10.52,8.5,3.4,0,6.25-1.15,8.39-3.51l2.9,3.4c-2.63,3.07-6.63,4.71-11.46,4.71-9.37,0-15.62-6.19-15.62-14.85s6.19-14.8,14.58-14.8,14.36,6.03,14.36,14.96c0,.44-.05,1.1-.11,1.59Zm-23.57-3.78h18.64c-.55-4.88-4.22-8.33-9.32-8.33s-8.77,3.4-9.32,8.33Z"/>
                <path class="cls-2" fill="#fff" d="m948.82,78.43l2.19-4.17c2.47,1.75,6.41,3.01,10.19,3.01,4.88,0,6.91-1.48,6.91-3.95,0-6.52-18.36-.88-18.36-12.44,0-5.21,4.66-8.71,12.11-8.71,3.78,0,8.06.99,10.58,2.63l-2.25,4.17c-2.63-1.7-5.54-2.3-8.39-2.3-4.6,0-6.85,1.7-6.85,4,0,6.85,18.42,1.26,18.42,12.55,0,5.26-4.82,8.61-12.55,8.61-4.82,0-9.59-1.48-12-3.4Z"/>
                <path class="cls-2" fill="#fff" d="m996.28,79.8c-1.59,1.37-4,2.03-6.36,2.03-5.86,0-9.21-3.23-9.21-9.1v-15.95h-4.93v-4.33h4.93v-6.36h5.26v6.36h8.33v4.33h-8.33v15.73c0,3.12,1.64,4.88,4.55,4.88,1.54,0,3.02-.49,4.11-1.37l1.64,3.78Z"/>
                <path class="cls-2" fill="#fff" d="m839.53,107.73v13.44h19.33v4.74h-19.33v15.58h-5.51v-38.55h27.2v4.79h-21.7Z"/>
                <path class="cls-2" fill="#fff" d="m883.36,112.02v5.12c-.44-.06-.83-.06-1.21-.06-5.67,0-9.2,3.47-9.2,9.86v14.54h-5.29v-29.19h5.07v4.9c1.87-3.41,5.51-5.18,10.63-5.18Z"/>
                <path class="cls-2" fill="#fff" d="m915.86,128.65h-23.68c.66,5.12,4.79,8.54,10.57,8.54,3.41,0,6.28-1.16,8.43-3.52l2.92,3.41c-2.64,3.08-6.66,4.74-11.51,4.74-9.42,0-15.69-6.22-15.69-14.92s6.22-14.87,14.65-14.87,14.43,6.06,14.43,15.03c0,.44-.06,1.1-.11,1.6Zm-23.68-3.8h18.72c-.55-4.9-4.24-8.37-9.36-8.37s-8.81,3.41-9.36,8.37Z"/>
                <path class="cls-2" fill="#fff" d="m949.56,128.65h-23.68c.66,5.12,4.79,8.54,10.57,8.54,3.41,0,6.28-1.16,8.43-3.52l2.92,3.41c-2.64,3.08-6.66,4.74-11.51,4.74-9.42,0-15.69-6.22-15.69-14.92s6.22-14.87,14.65-14.87,14.43,6.06,14.43,15.03c0,.44-.06,1.1-.11,1.6Zm-23.68-3.8h18.72c-.55-4.9-4.24-8.37-9.36-8.37s-8.81,3.41-9.36,8.37Z"/>
            </g>
            </g>
            <rect class="cls-1" fill="#0046aa" x="672.08" width="5.89" height="200"/>
            <path class="cls-1" fill="#0046aa" d="m121.49,116.71c0,5.87-4.76,10.63-10.63,10.63s-10.63-4.76-10.63-10.63,4.76-10.63,10.63-10.63,10.63,4.76,10.63,10.63Zm-10.63-77.45c-5.87,0-10.63,4.76-10.63,10.63s4.76,10.63,10.63,10.63,10.63-4.76,10.63-10.63-4.76-10.63-10.63-10.63Zm0,33.41c-5.87,0-10.63,4.76-10.63,10.63s4.76,10.63,10.63,10.63,10.63-4.76,10.63-10.63-4.76-10.63-10.63-10.63ZM21.26,39.25H0v121.49h121.49v-21.26H21.26V39.25Zm56.19,21.26c5.87,0,10.63-4.76,10.63-10.63s-4.76-10.63-10.63-10.63-10.63,4.76-10.63,10.63,4.76,10.63,10.63,10.63Zm-33.41,0c5.87,0,10.63-4.76,10.63-10.63s-4.76-10.63-10.63-10.63-10.63,4.76-10.63,10.63,4.76,10.63,10.63,10.63Zm136.91,12.15h-10.93v54.67s39.67,0,39.67,0v-10.93h-28.74v-43.74Zm363.51,27.34c0,10.44-4.71,16.77-8.66,20.25-5.34,4.7-12.18,7.08-20.34,7.08h-21.15v-54.67h21.15c8.16,0,15,2.38,20.35,7.09,3.95,3.48,8.65,9.81,8.65,20.25Zm-10.93,0c0-5.12-1.65-9.14-4.95-12.04-3.3-2.9-7.67-4.36-13.12-4.36h-10.22v32.8h10.22c5.45,0,9.82-1.45,13.12-4.36,3.3-2.9,4.95-6.92,4.95-12.05Zm65.71-16.4v-10.93h-41.29v54.67h41.29v-10.93h-30.36v-11.18h23.65v-10.45h-23.65v-11.18h30.36Zm-345.43-10.93l24.76,54.67h-12l-4.96-10.95h-25.73l-4.96,10.95h-12l24.76-54.67h10.13Zm2.85,32.79l-7.91-17.47-7.91,17.47h15.83Zm210.55-2c0,5.25-1.08,9.09-3.22,11.41-1.23,1.34-3.52,3.11-9.11,3.11s-7.88-1.78-9.11-3.11c-2.14-2.32-3.22-6.16-3.22-11.41v-30.79h-10.93v30.79c0,8.12,2.04,14.4,6.12,18.82,4.08,4.43,9.8,6.64,17.15,6.64s13.07-2.21,17.15-6.64c4.08-4.43,6.12-10.7,6.12-18.82v-30.79h-10.93v30.79Zm-186.36-19.86h17.26v43.74h10.93v-43.74h17.46v-10.93h-45.65v10.93Zm88.93,0h17.26v43.74h10.93v-43.74h17.46v-10.93h-45.65v10.93Zm-27.11,43.74h10.93v-54.67h-10.93v54.67Z"/>
        </g>
    </g>
    </svg>
)

export default LatitudeIf