import React, { createContext, useState, useEffect } from 'react';
import { get, values, sumBy } from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
import jwt from 'jsonwebtoken'
import moment from 'moment'
import { bcApi } from '../helpers/bigcommerce';
import { akeneoApi, flattenFields } from '../helpers/akeneo';
import { success, successAddToCart } from '../helpers/toast';
import { dataLayerPush, getStorage, infoStorage, setStorage } from '../helpers/general';
import { addWarrantyBundle, destroyMapping } from '../helpers/warranty';
import { LOCAL_STORE, parseJsonValue } from '../components/molecules/Localisr/Statics';
import { CLICK_ADD_CART, convertClickedObjects } from '../helpers/algolia';

const CartContext = createContext();

const initialState = {
  cartFetched: false,
  cartLoading: false,
  cartError: false,
  cart: {
    currency: {
      code: 'USD'
    },
    cartAmount: 0,
    lineItems: {},
    numberItems: 0,
    redirectUrls: {}
  },
  shippingMethod: getStorage(LOCAL_STORE.SHIPPING_METHOD) || '',
  selectedStore: {
    store_id: getStorage(LOCAL_STORE.UID) || '',
    store_name: getStorage(LOCAL_STORE.NAME) || '',
    store_address: getStorage(LOCAL_STORE.ADDRESS) || '',
    store_location: parseJsonValue(getStorage(LOCAL_STORE.LOCATION)) || false,
  },
  stockLoading: false,
  stockAvailability: [],
  priorityBracket: false,
  priorityProcessingAdded: false,
  warrantyMap: [],
  isSafeHandling: false
};

const getItemsIdInCart = lineItems => {
  return values(lineItems)
    .reduce((acc, curr) => {
      return [
        ...acc,
        ...curr.map(ite => ({ item_id: ite.id, quantity: ite.quantity }))
      ];
    }, [])
    .filter(x => x);
};

export const CartProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const [notifications, updateNotifications] = useState([]);

  const bracketsData = useStaticQuery(graphql`
      query {
        allBuilderModels {
          brackets: priorityProcessingBrackets(sort: {data: {minimumBracketPrice: 1}}) {
            data {
              maximumBracketPrice
              minimumBracketPrice
              productSku
            }
          }
        }
      }
  `);

  const togglePriorityProcessing = () => {
    // console.log("Toggle Priority");
    // console.log("Has priority set already?", state.priorityProcessingAdded);
    return new Promise(res => {
      if (state.priorityProcessingAdded) {
        // Is added - remove
        const lineItem = state.cart.lineItems.digital_items.find(item => item.sku === state.priorityBracket.productSku);
        removeItemFromCart(lineItem.id).then(() => {
          res(true);
        });
      } else {
        // Not added - add
        addToCart(state.priorityBracket.product.variants[0].product_id, state.priorityBracket.product.variants[0].id).then(() => {
          res(true);
        });
      }
    });
  }

  const addNotification = (text, type = 'notify') => {
    updateNotifications([...notifications, { text, type, id: Date.now() }]);
  };

  const removeNotification = id => {
    updateNotifications(notifications.filter(ntfy => ntfy.id !== id));
  };

  const loadQuote = async quoteToken => {
    // TODO: If we can work out what secret BC uses to generate it, we can further secure it.
    // jwt.verify(quoteToken, process.env.BC_CLIENT_SECRET, (err, decoded) => {
    //   console.log(err, decoded);
    // })

    const quote = jwt.decode(quoteToken);
    if (quote && quote.iss === process.env.BC_PATH && quote.exp > moment().unix() && get(quote, 'domain.cart.id')) {
      const cartId = get(quote, 'domain.cart.id');
      await bcApi('carts', 'GET', null, null, cartId).then(({response, status}) => {
        if (response) {
          return refreshCart({...response, status});
        } else {
          setState({ ...state, cartFetched: true });
          return false;
        }
      })
    }
  }

  const fetchCart = async () => {
    setState({ ...state, cartFetched: false });
    return await bcApi('carts')
      .then(({ response }) => {
        if (response) {
          return refreshCart(response);
        } else {
          setState({ ...state, cartFetched: true });
          return false;
        }
      })
      .catch(error => {
        setState({ ...state, cartLoading: false, cartFetched: false, cartError: error });
        return false;
      });
  };

  useEffect(() => {
    fetchCart();
    // eslint-disable-next-line
  }, []);

  const calculateNumberItems = lineItems => {
    const {
      physical_items = [],
      digital_items = [],
      custom_items = [],
      gift_certificates = []
    } = lineItems || {};
    const numberPhysical = sumBy(physical_items, ite => ite.quantity) || 0;
    const numberDigital = sumBy(digital_items, ite => ite.quantity) || 0;
    const numberCustom = sumBy(custom_items, ite => ite.quantity) || 0;
    const numberGift = sumBy(gift_certificates, ite => ite.quantity) || 0;
    return numberPhysical + numberDigital + numberCustom + numberGift;
  };

  const updateState = response => {
    return new Promise(res => {
      const lineItems = response.data.line_items;
      const cartAmount = response.data.cart_amount;
      const baseAmount = response.data.base_amount;
      const currency = response.data.currency;
      const cartId = response.data.id;
      const coupons = response.data.coupons;
      let isSafeHandling = false;
      let priorityProcessingAdded = false;

      // Fetch additional product data
      const skus = response.data.line_items.physical_items.map(product => product.sku);
      skus.push(...response.data.line_items.digital_items.map(product => product.sku));
      const productIds = response.data.line_items.physical_items.map(product => product.product_id);
      productIds.push(...response.data.line_items.digital_items.map(product => product.product_id))

      bcApi(`catalog/products?include=variants,custom_fields&id:in=${productIds.join(',')}`).then(productData => {
        const productInfo = {};
        // const skus = [];
        let warrantyList = [];
        productData.response.data.map(product => {
          productInfo[product.id] = product;
          // skus.push(product.sku);

          // get the skus of warranties
          if(product.custom_fields.length > 0) {
            const warrantyValueList = product.custom_fields.filter(pd => pd.name === 'warranties');
            if(warrantyValueList.length === 1) {
              const wd = [...warrantyValueList[0].value.replace(/\s/g,'').split(';')];
              productInfo[product.id].warranties = wd.map((warranty) => {return {sku: warranty}});
              warrantyList.push(...wd);
            }
          }

          const safeHandlers = product.custom_fields.filter(pd => pd.name === 'safe_handling');
          if (safeHandlers.length > 0) {
            isSafeHandling = safeHandlers[0].value === "true" || safeHandlers[0].value === true ? true : false;
          }
          return true;
        });
        
        skus.push(...new Set(warrantyList)); // append and remove duplicates
        akeneoApi(`products?limit=100&search={"identifier":[{"operator":"IN", "value":${JSON.stringify(skus)}}]}`).then(async result => {
          if (result.status === 200 && result.response._embedded.items.length > 0) {
            const items = result.response._embedded.items;
            Object.values(productInfo).map(product => {
              const akProduct = items.find(p => p.parent === product.sku || p.identifier === product.sku);
              if (akProduct) {
                productInfo[product.id].detail = flattenFields(akProduct);
              }

              //return warranty information from Akeneo
              let warranty_description = [];
              for(let x = 0; x < product.warranties?.length; x++) {
                const warranty_detail = flattenFields(items.find(item => item.identifier === product.warranties[x].sku));
                warranty_description.push(warranty_detail);
              }

              // override property;
              productInfo[product.id].warranties = warranty_description;

              return true;
            });
          }

          // Find current bracket
          let bracketAmount = 0;
          const itemTypes = Object.keys(lineItems);
          itemTypes.map(type => {
            lineItems[type].map(item => {
              if (!(bracketsData?.allBuilderModels.brackets.find(bracket => item.sku === bracket.data.productSku))) {
                bracketAmount += item.extended_sale_price;
              }
              return true;
            })
            return true;
          })
          const bracketData = bracketsData?.allBuilderModels.brackets.find(bracket => bracketAmount >= bracket.data.minimumBracketPrice && bracketAmount <= bracket.data.maximumBracketPrice);
          const priorityBracketData = bracketData?.data;
          // console.log("PriorityBracketData", priorityBracketData);
          if (bracketData) {
            // Fetch bracket product
            const bracketProduct = await bcApi(`catalog/products?include=variants&sku:in=${bracketData?.data.productSku}`);
            // console.log("Fetch Bracket product", bracketProduct);
            if (bracketProduct.status === 200 && 'data' in bracketProduct.response) {
              priorityBracketData.product = bracketProduct.response.data[0]
            }

            // Check if wrong priority is set in cart and remove
            // console.log("bracket SKU", priorityBracketData.productSku)
            bracketsData?.allBuilderModels.brackets.map(bracket => {
              if (bracket.data.productSku !== priorityBracketData.productSku) {
                const oldPriority = lineItems.digital_items.find(item => item.sku === bracket.data.productSku);
                if (oldPriority) {
                  // console.log("Removing", oldPriority);
                  removeItemFromCart(oldPriority.id, cartId);
                }
              }

              return true;
            });

            // Set bool value if found
            // console.log("Cart line items", lineItems);
            if (lineItems && lineItems.digital_items.length > 0) {
              priorityProcessingAdded = typeof lineItems.digital_items.find(item => item.sku === priorityBracketData.productSku) !== 'undefined';
            }
          }

          let warrantyMap = [];
          if(response.warrantyBundle === true) {
            const lastIndexPhysical = lineItems.physical_items.length - 1;
            const lastIndexDigital = lineItems.digital_items.length - 1;
            addWarrantyBundle(lineItems.physical_items[lastIndexPhysical], lineItems.digital_items[lastIndexDigital]);
          }

          const newState = {
            ...state,
            cartFetched: true,
            cartLoading: false,
            updatingItem: false,
            isSafeHandling: isSafeHandling,
            coupons,
            cart: {
              cartId,
              currency,
              cartAmount,
              lineItems,
              productInfo,
              baseAmount,
              numberItems: calculateNumberItems(lineItems),
              redirectUrls: response.data.redirect_urls || state.cart.redirectUrls
            },
            priorityBracket: priorityBracketData,
            warrantyMap: warrantyMap,
            priorityProcessingAdded
          };
          setState(newState);
          refreshCheckout(cartId, newState);
          res(true);
        });
      });
    });
  }

  const refreshCart = response => {
    if (response.status === 204 || response.status === 404) {
      setState({ ...state, cartLoading: false });
      return false;
    } else {
      return updateState(response);
    }
  };

  const refreshCheckout = (cartId, theState) => {
    bcApi(`checkouts/${cartId}`).then(response => {
      if (response.status === 200) {
        setState({...theState, checkout: response.response.data});
      }
    });
  };

  const initCheckout = async () => {
    const {response, status} = await bcApi(`checkouts/${state.cart.cartId}`);
    if (status === 200) {
      setState({...state, checkout: response.data});
      return {...state, checkout: response.data};
    }
    return state;
  };

  const addToCart = (productId, variantId, retry, quantity = 1, disableNotification = false, customPrice, productWarrantyBundle) => {
    return new Promise((res, rej) => {
      setState({ ...state, addingToCart: productId });
      const bcApiBody = JSON.stringify({
        line_items: [
          {
            quantity: parseInt(quantity, 10),
            product_id: parseInt(productId, 10),
            variant_id: parseInt(variantId, 10),
            list_price: customPrice ? parseInt(customPrice, 10) : undefined,
          }
        ],
      });
      bcApi('carts/items', 'POST', bcApiBody)
        .then(({ response, status }) => {
          if (status === 404 && !retry) {
            destroyMapping();
            // re create a cart if cart was destroyed
            return bcApi('carts').then(() =>
              addToCart(productId, variantId, true, quantity, disableNotification, undefined, productWarrantyBundle)
            );
          }

          //snackbar implementation
          status < 300 && addNotification('Item added successfully');
          if(disableNotification === false) successAddToCart('Product added to cart');
          
          if (infoStorage('algolia_insights')) {
            const insights = JSON.parse(getStorage('algolia_insights'));
            const thisInsightData = insights.filter((arr) => {
              return arr.productId === productId
            })[0];
            if (thisInsightData) {
              convertClickedObjects({...thisInsightData, eventName: CLICK_ADD_CART});
            }
          }

          if ('data' in response) {

            if(productWarrantyBundle) {
              const digitalItemsLength = response.data.line_items.digital_items.length;
              const addedWarranty = response.data.line_items.digital_items[digitalItemsLength - 1] ; // get the item added
              addWarrantyBundle(productWarrantyBundle,addedWarranty);
            }

            const addedProduct = [];
            const itemTypes = Object.keys(response.data.line_items);
            itemTypes.map(itemType => {
              response.data.line_items[itemType].map(item => {
                if (item.product_id === productId) {
                  addedProduct.push(item)
                }
                return true;
              });
              return true;
            });

            if (addedProduct && addedProduct.length > 0) {
              dataLayerPush('add_to_cart', null, addedProduct[0]);
            }
            updateState(response).then(() => {
              res(true);
            });
          } else if (response.status > 300 && 'title' in response) {
            setState({ ...state, addingToCart: false, addToCartError: response });
            rej(response.title);
          }
        })
        .catch(error => {
          setState({ ...state, addingToCart: false, addToCartError: error });
          rej(error);
        });
    });
  };

  const addAllToCart = (items, retry, warrantyBundle = false) => {
    return new Promise((res, rej) => {
      const lineItems = items.filter(product => 'product_id' in product ? (product.product_id > 0) : (parseInt(product[0], 10) > 0)).map(product => {
        if ('product_id' in product) {

          const rawItem = {
            quantity: 'quantity' in product ? parseInt(product.quantity, 10) : 1,
            product_id: parseInt(product.product_id, 10),
            variant_id: parseInt(product.variant_id, 10),
          }

          if(product.custom_price) rawItem['list_price'] = product.custom_price;
          return rawItem;

          // return {
          //   quantity: 'quantity' in product ? parseInt(product.quantity, 10) : 1,
          //   product_id: parseInt(product.product_id, 10),
          //   variant_id: parseInt(product.variant_id, 10),
          // };
        } else {

          const rawItem = {
            quantity: 1,
            product_id: parseInt(product[0], 10),
            variant_id: parseInt(product[1], 10)
          }

          if(product.custom_price) rawItem['list_price'] = product.custom_price;
          return rawItem;

          // return {
          //   quantity: 1,
          //   product_id: parseInt(product[0], 10),
          //   variant_id: parseInt(product[1], 10)
          // };
        }
      });

      if (lineItems.length > 0) {
        const bcApiBody = JSON.stringify({
          line_items: lineItems
        });
        bcApi('carts/items', 'POST', bcApiBody)
          .then(({ response, status }) => {
            if (status === 404 && !retry) {
              destroyMapping();
              // re create a cart if cart was destroyed
              return bcApi('carts').then(() => addAllToCart(items, true, warrantyBundle));
            }

            if (status === 422) {
              rej(response);
              return;
            }

            let modifiedResponse = response;
            if(warrantyBundle === true) {
              modifiedResponse = {
                ...response,
                warrantyBundle: true
              }
            }

            status < 300 && addNotification('Item added successfully');
            success('Product added to cart');

            updateState(modifiedResponse).then(() => {
              res(true);
            });
          })
          .catch(error => {
            setState({ ...state, addingToCart: false, addToCartError: error });
            rej(error);
          });
      } else {
        setState({ ...state, addingToCart: false, addToCartError: { title: 'No products to add' } });
        rej({ title: 'No products to add' });
      }
    });
  };

  const updateItemInCart = (itemId, updatedItemData) => {
    const bcApiBody = JSON.stringify(updatedItemData);
    // @see https://developer.bigcommerce.com/api-reference/store-management/carts/cart-items/updatecartlineitem
    bcApi(`carts/${state.cart.cartId}/items/${itemId}`, 'PUT', bcApiBody)
      .then(({ response }) => {
        refreshCart(response);
      })
      .catch(error => {
        setState({ ...state, cartLoading: false, cartError: error });
      });
  };

  const removeItemFromCart = (itemId, manualCartId) => {
    // @see https://developer.bigcommerce.com/api-reference/store-management/carts/cart-items/deletecartlineitem
    // stale issue
    setState((state) => {
      return {
        ...state,
        updatingItem: itemId
      }
    });

    const cartId = state.cart.cartId || manualCartId;

    return new Promise((res, rej) => {
      bcApi(`carts/${cartId}/items/${itemId}`, 'DELETE')
        .then(({ response, status }) => {
          // addNotification('Item removed successfully');
          if (status === 204) {
            // Empty cart and cart no longer exists
            setState({...initialState, cartFetched: true});
            return true;
            // return bcApi('carts').then(() => {
            //   /* do nothing */
            // });
          }
          // addNotification('Item removed successfully');
          const stockAvailability = [...state.stockAvailability];
          if (stockAvailability.length > 0) {
            const availabilityData = [];
            response.data.line_items.physical_items.map(a => {
              const availableData = stockAvailability.find(s => s.sku === a.sku);
              if (availableData) {
                availabilityData.push(availableData);
              }
              return true;
            });
            updateStockAvailability(availabilityData);
            refreshCart(response).then(() => res(true));
            
          } else {
            refreshCart(response).then(() => res(true));;
          }
        })
        .catch(error => {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(false);
        });
    });
  };

  const updateCartItemQuantity = (item, action) => {
    let newQuantity;
    if (['minus', 'plus'].indexOf(action) > -1) {
      newQuantity = item.quantity + (action === 'minus' ? -1 : 1);
    } else {
      newQuantity = action;
    }

    // stale issue
    // setState({ ...state, updatingItem: item.id });
    setState((state) => {
      return {
        ...state,
        updatingItem: item.id
      }
    });
    

    if (newQuantity < 1) {
      return removeItemFromCart(item.id);
    }
    let productVariantReferences = null;

    if (typeof item.product_id !== 'undefined') {
      productVariantReferences = {
        product_id: item.product_id,
        variant_id: item.variant_id
      };
    }

    updateItemInCart(item.id, {
      line_item: {
        quantity: newQuantity,
        ...productVariantReferences
      }
    });
  };

  const addCoupons = async coupon_code => {
    return new Promise(async (res, rej) => {
      const endpoint = `checkouts/${state.cart.cartId}/coupons`;
      const reqBody = { coupon_code };
      try {
        const { response, status } = await bcApi(endpoint, 'POST', reqBody);
        const coupons = get(response, 'data.coupons');
        if (status === 200 && coupons) {
          setState({
            ...state,
            coupons
          });
          res(coupons);
        } else {
          rej(response);
        }
      } catch (error) {
        setState({ ...state, cartLoading: false, cartError: error });
        rej(error);
      }
    });
  };

  const removeCoupons = async coupon_code => {
    const endpoint = `checkouts/${state.cart.cartId}/coupons/${coupon_code}`;
    try {
      const { response, status } = await bcApi(endpoint, 'DELETE');
      const coupons = get(response, 'data.coupons');
      if (status === 200 && coupons) {
        setState({
          ...state,
          coupons
        });
        return coupons;
      } else {
        return response;
      }
    } catch (error) {
      console.log(error);
      setState({ ...state, cartLoading: false, cartError: error });
      return error;
    }
  };

  const addConsignments = async shippingAddress => {
    const { cartId, lineItems } = state.cart;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/consignments`;
      const req_body = [
        {
          shipping_address: shippingAddress,
          line_items: getItemsIdInCart(lineItems)
        }
      ];

      try {
        const { response, status } = await bcApi(endpoint, 'POST', req_body);
        if (status === 200) {
          setState({
            ...state,
            consignments: response.data.consignments
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateConsignments = async (shippingAddress, consignmentId) => {
    // PUT /checkouts/{checkoutId}/consignments/{consignmentId}
    const {
      cart: { cartId, lineItems }
    } = state;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/consignments/${consignmentId}`;
      const req_body = {
        shipping_address: shippingAddress,
        line_items: getItemsIdInCart(lineItems)
      };
      try {
        const { status } = await bcApi(endpoint, 'PUT', req_body);
        if (status === 200) {
          setState({
            ...state,
            consignments: []
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeConsignments = async (_state) => {
    const { response, status } = await bcApi(`checkouts/${state.cart.cartId}`);
    if (status === 200) {
      const _consignments = response.data.consignments || [];
      if (_consignments && typeof _consignments !== 'undefined' && _consignments.length) {
        const endpoint = `checkouts/${state.cart.cartId}/consignments/${_consignments[0].id}`;
        const resRemoved = await bcApi(endpoint, 'DELETE');
        if (resRemoved.status === 200) {
          setState({ ..._state, consignments: resRemoved.response.data.consignments || [], checkout: resRemoved.response.data });
        }
      }
    }
  }

  const addGiftCertificates = async giftCertCode => {
    const { cartId } = state.cart;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/gift-certificates`;
      // TODO: make this request to work
      try {
        const { response, status } = await bcApi(endpoint, 'POST', {
          giftCertificateCode: giftCertCode
        }, 'sf');
        console.log(status, response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const changeShippingMethod = async (_method, _state = false) => {
    setStorage(LOCAL_STORE.SHIPPING_METHOD, _method);
    const _newState = { ...(_state ? _state : state), shippingMethod: _method };
    setState(_newState);

    if (_method === 'collect') {
      const result = await setPickupConsignment(_newState);
      if (result === 'forceDelivery') {
        await removeConsignments({ ..._newState, shippingMethod: 'delivery' });
        storeCheckoutData({ shipping: {} });
        return 'forceDelivery';
      } else {
        return true;
      }
    } else {
      await removeConsignments(_newState);
      storeCheckoutData({ shipping: {} });
      return true;
    }
  }

  const changeSelectedStore = (_store) => {
    // store addresses
    setStorage(LOCAL_STORE.UID, _store.store_id);
    setStorage(LOCAL_STORE.NAME, _store.store_name);
    setStorage(LOCAL_STORE.ADDRESS, _store.store_address);
    setStorage(LOCAL_STORE.LOCATION, JSON.stringify(_store.store_location));

    // update state
    setState({ ...state, selectedStore: _store });
  }

  const storeCheckoutData = (obj) => {
    const storeKey = '__jammcd';
    const existingJSON = getStorage(storeKey) || JSON.stringify({});
    const existing = JSON.parse(existingJSON);
    const newSet = JSON.stringify({...existing, ...obj});
    setStorage(storeKey, newSet);
  }

  const setPickupConsignment = async (_state) => {
    await removeConsignments(_state);

    const { cartId, lineItems } = _state.cart;
    const { selectedStore } = _state;
    const endpoint = `checkouts/${cartId}/consignments?include=consignments.available_shipping_options`;

    const stateTranslations = {
      'ACT': 'Australian Capital Territory',
      'NSW': 'New South Wales',
      'NT': 'Northern Territory',
      'QLD': 'Queensland',
      'SA': 'South Australia',
      'TAS': 'Tasmania',
      'VIC': 'Victoria',
      'WA': 'Western Australia'
    };

    if (lineItems.physical_items.length === 0) {
      // Cart can not be collected
      return 'forceDelivery';
    }

    if (`${selectedStore.store_name}`.trim() === '') {
      return false;
    }

    try {
      const resAddConsignment = await bcApi(endpoint, 'POST', [
        {
          shipping_address: {
            first_name: selectedStore.store_name,
            last_name: selectedStore.store_id,
            address1: selectedStore.store_location.address_1,
            city: selectedStore.store_location.suburb,
            phone: selectedStore.store_location.phone,
            email: selectedStore.store_location.email,
            postal_code: selectedStore.store_location.postcode,
            state_or_province: Object.keys(stateTranslations).indexOf(selectedStore.store_location.state) > -1 ? stateTranslations[selectedStore.store_location.state] : selectedStore.store_location.state,
            country_code: selectedStore.store_location.country,
          },
          line_items: getItemsIdInCart(lineItems)
        }
      ]);
      if (resAddConsignment.status === 200 && resAddConsignment.response.data.consignments.length > 0) {
        const _pickupOption = resAddConsignment.response.data.consignments[0].available_shipping_options.filter(a => ['click', 'collect', 'pickup'].filter(b => a.description.toLowerCase().includes(b)).length > 0);
        if (_pickupOption.length) {
          const endpoint = `checkouts/${state.cart.cartId}/consignments/${resAddConsignment.response.data.consignments[0].id}`;
          const resUpdatedCheckout = await bcApi(endpoint, 'PUT', { shipping_option_id: _pickupOption[0].id });
          if (resUpdatedCheckout.status === 200) {
            setState({
              ..._state,
              consignments: resAddConsignment.response.data.consignments,
              checkout: resUpdatedCheckout.response.data
            });
            storeCheckoutData({
              shipping: resUpdatedCheckout.response.data.consignments[0].shipping_address
            });
            return true;
          }
          return false;
        } else {
          // Preselected store - cart can not be collected
          return 'forceDelivery';
        }
      } else {
        // Preselected store - cart can not be collected
        return 'forceDelivery';
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const loadingStock = loading => {
    setState({ ...state, stockLoading: loading});
  }

  const updateStockAvailability = async (_stocks) => {
    setState({ ...state, stockAvailability: _stocks });
  }

  const updateWarrantyMap = (_warrantyMap) => {
    setState({ ...state, warrantyMap: _warrantyMap });
  }

  return (
    <CartContext.Provider
      value={{
        state,
        loadQuote,
        fetchCart,
        addToCart,
        addAllToCart,
        addCoupons,
        removeCoupons,
        removeItemFromCart,
        updateCartItemQuantity,
        notifications,
        addNotification,
        removeNotification,
        addConsignments,
        addGiftCertificates,
        updateConsignments,
        changeShippingMethod,
        changeSelectedStore,
        loadingStock,
        updateStockAvailability,
        initCheckout,
        togglePriorityProcessing,
        updateWarrantyMap,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
