/**
 * Akeneo API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 3 is assumed
 * 
 * @return  {Object}                {response, status}
 *
    import { akeneoApi } from '../helpers/akeneo'

    akeneoApi('endpoint', 'POST', bodyObject, true).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function akeneoApi(endpoint, method, body) {
    const options = {
        method: method ? method : 'GET',
        credentials: 'same-origin',
        mode: 'same-origin',
    };
    
    if (body) {
        let bodyString = body;
        if (typeof body === 'object') {
        bodyString = JSON.stringify(body);
        }
    
        options.body = bodyString;
    }
    
    const parseJson = async response => {

        const text = await response.text();
        try {
            const json = JSON.parse(text);
            return json;
        } catch (err) {
            return text;
        }
    };
    
    return await fetch(
        `/.netlify/functions/akeneo?endpoint=${btoa(endpoint)}`,
        options
    ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

/**
 * Flatten values fields
 * 
 * Iterates of a product objects values field and moves each field out to the route
 * 
 * @param   {Object} product        The Akeneo product object
 * @param   {Object} extraData      Extra data from other objects to add to the product object (ie brand data)
 * 
 * @return  {Object}                The treated product object
 *
    import { flattenFields } from '../helpers/akeneo'

    flattenFields(product)
 */
function flattenFields(product, extraData = {}) {
    const productValues = {};
    if (product && 'values' in product) {
        Object.keys(product.values).map(value => {
            if (product.values[value] && product.values[value].length > 1) {
                // TODO: Need to handle multiple values for a field from Akeneo here
            } else if (product.values[value] && Array.isArray(product.values[value]) && typeof product.values[value][0].data === 'object') {
                if ('amount' in product.values[value][0].data) {
                    // Data value is measurement
                    productValues[value] = product.values[value][0].data.amount;
                    if ('unit' in product.values[value][0].data) {
                      productValues[`unit_${value}`] = product.values[value][0].data.unit;
                    }
                } else if (product.values[value][0].data.length > 0) {
                    if (typeof product.values[value][0].data[0] === 'object' && 'amount' in product.values[value][0].data[0]) {
                        // Data value is price - get first value for now
                        // TODO: Handle multiple currencies
                        productValues[value] = product.values[value][0].data[0].amount;
                    } else if ('attributeOptions' in extraData) {
                        productValues[value] = product.values[value][0].data.map(attrValue => {
                          const attributeOptions = extraData.attributeOptions.find(option => option.attribute === value && option.code === attrValue);
                          return attributeOptions ? attributeOptions.labels.en_AU : attrValue;
                        });
                        if (productValues[value].length === 1) productValues[value] = productValues[value][0];
                    } else {
                      productValues[value] = product.values[value][0].data.length === 1 ? product.values[value][0].data[0] : product.values[value][0].data;
                    }
                } else productValues[value] = null;
            } else if (value === 'brand' && product.values[value] && 'brands' in extraData) {
                productValues[value] = extraData.brands.find(brand => brand.code === product.values[value][0].data);
            } else if (value === 'product_id' && product.values[value] && 'productRatings' in extraData) {
                const attributeOptions = extraData.productRatings.find(option => {
                    return product.values[value] && parseInt(option.productIdentifier) === parseInt(product.values[value][0].data);
                });
                productValues[value] = product.values[value][0].data;
                productValues['ratings_summary'] = attributeOptions || null;
            } else if (product.values[value]) {
                productValues[value] = Array.isArray(product.values[value]) ? product.values[value][0].data : product.values[value];
            } else productValues[value] = null;

            return true;
        });
        if (!('sku' in product) && 'identifier' in productValues) {
            productValues.sku = productValues.identifier;
        }
        const productData = {...product, ...productValues};
        return productData;
    }

    return product;
}

/**
 * Fetch image path from image assets object
 * 
 * @param {object} obj 
 * @returns url
 * 
    import { getImagePath } from '../helpers/akeneo'

    getImagePath(imgNode)
 */
function getImagePath(obj) {
    let url = '';
    // The replace values here support the Webdav handling of /0/ folders not creating or checking correctly.
    if (obj && obj.values && obj.values.image) url = `${process.env.WEBDAV_PATH}/content/${obj.values.image[0].data.replace(/0\//g, '0f/')}`;
    if (obj && obj.values && obj.values.media) url = `${process.env.WEBDAV_PATH}/content/${obj.values.media[0].data.replace(/0\//g, '0f/')}`;
    if (obj && obj.values && obj.values.medialink) url = !obj.values.medialink[0].data.startsWith('http') ? `${process.env.WEBDAV_PATH}/content/${obj.values.medialink[0].data.startsWith('/') ? obj.values.medialink[0].data.substr(1) : obj.values.medialink[0].data}` : obj.values.medialink[0].data;

    return url;
}

/**
 * Traverse category tree
 * 
 * Traverse categories to provide full URL structure for category
 * 
 * @param   {Object} categories     Akeneo categories
 * @param   {Object} leaf           Current category
 * @param   {Object} branch         Collected categories
 * 
 * @return  {String}                URL for starting category
 *
    import { traverseCategoryTree } from '../helpers/akeneo'

    traverseCategoryTree(categories, currentCategory, [])
 */
function traverseCategoryTree(categories, leaf, branch) {
    const newBranch = [...branch];
    newBranch.push(leaf.code);
    if (leaf.parent === 'master') {
      const path = newBranch.reverse().join('/');
      return `/${path}/`;
    } else {
      const parentLeaf = categories.find(category => category.code === leaf.parent);
      if (parentLeaf) {
        return traverseCategoryTree(categories, parentLeaf, newBranch);
      }
    }
}

/**
 * Build category tree
 * 
 * Output multi-level object of category data
 * 
 * @param   {Object} categories     Flat Akeneo categories object
 * 
 * @return  {Object}                Multi level categories
 *
    import { buildCategoryTree } from '../helpers/akeneo'

    buildCategoryTree(categories)
 */
function buildCategoryTree(categories) {
    const parentMap = {};
    categories.map(category => {
        if (!(category.code in parentMap)) parentMap[category.code] = [];
        if (!(category.parentIdentifier in parentMap)) parentMap[category.parentIdentifier] = [];
        parentMap[category.parentIdentifier].push(category.code);

        return true;
    });

    const iterateCategories = (categoryLevel) => {
        return categoryLevel.map(category => {
            const branch = categories.find(c => c.code === category);
            if (parentMap[category].length > 0) {
                branch.children = iterateCategories(parentMap[category]);
            } else {
                branch.children = [];
            }

            return branch;
        });
    }

    return iterateCategories(parentMap.master);
}

export { 
    akeneoApi,
    flattenFields,
    getImagePath,
    traverseCategoryTree,
    buildCategoryTree
};