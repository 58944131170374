import React from 'react'

const Search = () => {
    return(
        <svg
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{"Search"}</title>
        <g
          stroke="currentColor"
          strokeWidth={2}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M14.355 7.678A6.677 6.677 0 111 7.678a6.677 6.677 0 1113.355 0zM16.483 16.468l-4.092-4.06" />
        </g>
      </svg>);
}

export default Search