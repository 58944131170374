import React from 'react'

const Heart = () => (
    <svg
      viewBox="0 0 22 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.365 10.965h0l7.445 7.25c.13.077.29.077.422 0l7.279-7.092c1.654-1.744 2.481-3.428 2.481-5.03a5.067 5.067 0 00-1.34-3.73 5.151 5.151 0 00-3.805-1.317 4.472 4.472 0 00-1.332.227c-.45.139-.879.343-1.273.604-.37.241-.724.508-1.06.796a8.122 8.122 0 00-.868.763l-.322.327-.323-.327a8.073 8.073 0 00-.827-.747c-.34-.301-.7-.581-1.075-.838a5.02 5.02 0 00-1.282-.612 4.472 4.472 0 00-1.332-.226A5.14 5.14 0 002.348 2.33a5.067 5.067 0 00-1.34 3.73c.002.512.088 1.018.256 1.5.141.459.333.901.571 1.317.212.39.458.76.736 1.106l.794.982z"
        stroke="currentColor"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
)

export default Heart