const LOCAL_STORE = {
    ID: 'slm_store_id',
    UID: 'slm_unique_id',
    NAME: 'slm_store_name',
    ADDRESS: 'slm_store_address',
    LOCATION: 'slm_store_location',
    MAP_PERMISSION: 'slm_map_permission',
    TOKEN: 'slm_user_token',
    SHIPPING_METHOD: 'slm_shipping_option',
};

const parseJsonValue = (_content) => {
    let _val = false;
    try {
        _val = JSON.parse(_content);
    } catch (_e) {}
    return _val;
}

export {
    LOCAL_STORE,
    parseJsonValue
};